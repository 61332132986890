import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import userPool from './cognitoConfig';
import { encryptToken } from './authUtils';
import styles from './Login.module.css';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState({ text: '', type: '' });
  const navigate = useNavigate();

  const onSubmit = (event) => {
    event.preventDefault();

    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    const user = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        console.log('Login successful!', data);
        const idToken = data.getIdToken().getJwtToken();
        const encryptedToken = encryptToken(idToken);
        sessionStorage.setItem("jwt", encryptedToken);

        setMessage({ text: 'Login successful! Redirecting...', type: 'success' });

        // Redirect after a short delay
        setTimeout(() => navigate('/'), 2000);
      },
      onFailure: (err) => {
        console.error('Login failed:', err);
        setMessage({ text: err.message || 'An error occurred', type: 'error' });
      },
    });
  };

  const handleSignupClick = () => {
    navigate('/signup');
  };

  const handleForgotPasswordClick = () => {
    navigate('/forgot-password');
  };

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <h2 className={styles.title}>Login</h2>

        {message.text && (
          <div className={`${styles.message} ${message.type === 'error' ? styles.error : styles.success}`}>
            {message.text}
          </div>
        )}

        <form onSubmit={onSubmit}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={styles.input}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className={styles.input}
            required
          />
          <button type="submit" className={styles.button}>Login</button>
        </form>
        <button onClick={handleSignupClick} className={styles.linkButton}>Signup</button>
        <button onClick={handleForgotPasswordClick} className={styles.linkButton}>Forgot Password?</button>
      </div>
    </div>
  );
}

export default Login;
  