// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './Login';
import Signup from './Signup';
import ConfirmAccount from './ConfirmAccount'; 
import MainPage from './MainPage';
import ForgotPassword from './ForgotPassword';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/verify" element={<ConfirmAccount />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/" element={<MainPage />} />
            </Routes>
        </Router>
    );
}

export default App;
