// authUtils.js
import PsiogEncryption from 'psiog-crypt';

const encryptionKey = 'process.env.REACT_APP_ENCRYPTION_KEY'; // Corrected to use the environment variable
const psiog = new PsiogEncryption(encryptionKey);

// Encrypt the token before storing
export function encryptToken(token) {
    try {
        return psiog.encrypt(token);
    } catch (error) {
        console.error("Encryption failed:", error);
        throw error;
    }
}

// Decrypt the token
export function decryptToken(encryptedToken) {
    try {
        return psiog.decrypt(encryptedToken);
    } catch (error) {
        console.error("Decryption failed:", error);
        throw error;
    }
}

// Check authorization and token expiration
export function checkAuthorization() {
    const encryptedToken = sessionStorage.getItem("jwt");

    if (!encryptedToken) {
        console.warn('No JWT token found in session.');
        return false;
    }

    const token = decryptToken(encryptedToken);

    // Check if token matches the in-memory token and if it is expired
    if (isTokenExpired(token)) {
        console.warn('Unauthorized access - token has expired');
        logout();
        return false;
    }
    return true;
}

// Check if JWT token is expired
function isTokenExpired(token) {
    try {
        const payload = JSON.parse(atob(token.split('.')[1])); // Decode token payload
        const expiration = payload.exp * 1000; // Convert exp to milliseconds
        return Date.now() > expiration;
    } catch (error) {
        console.error('Error checking token expiration:', error);
        return true;
    }
}

// Logout function to clear tokens and redirect
export function logout() {
    sessionStorage.clear();
    window.location.href = 'https://digipo.auth.us-west-2.amazoncognito.com/logout?client_id=2mnv17voa7e8q6banlg0j0qth&logout_uri=https://main.d11ggxyoy8k64j.amplifyapp.com/'; // Replace with your actual sign-out URL
}
