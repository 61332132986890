import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import userPool from './cognitoConfig';
import styles from './Signup.module.css';

function Signup() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [gender, setGender] = useState('');
  const [phone_number, setPhone] = useState('');
  const [birthdate, setBirthDate] = useState('');
  const [message, setMessage] = useState({ text: '', type: '' });
  const navigate = useNavigate();

  const onSubmit = (event) => {
    event.preventDefault();

    const attributeList = [
      new CognitoUserAttribute({ Name: 'email', Value: email }),
      new CognitoUserAttribute({ Name: 'name', Value: name }),
      new CognitoUserAttribute({ Name: 'gender', Value: gender }),
      new CognitoUserAttribute({ Name: 'phone_number', Value: phone_number }),
      new CognitoUserAttribute({ Name: 'birthdate', Value: birthdate }),
    ];

    userPool.signUp(email, password, attributeList, null, (err, result) => {
      if (err) {
        console.error('Error during signup:', err);
        setMessage({ text: err.message || 'An error occurred', type: 'error' });
      } else {
        console.log('User signup successful:', result.user);
        setMessage({ text: 'Signup successful! Please check your email for verification.', type: 'success' });
        sessionStorage.setItem("email", email);

        // Navigate to verification page after a short delay
        setTimeout(() => navigate('/verify'), 2000);
      }
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <h2 className={styles.title}>Signup</h2>

        {message.text && (
          <div className={`${styles.message} ${message.type === 'error' ? styles.error : styles.success}`}>
            {message.text}
          </div>
        )}

        <form onSubmit={onSubmit}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={styles.input}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className={styles.input}
            required
          />
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className={styles.input}
            required
          />
          <select
            value={gender}
            onChange={(e) => setGender(e.target.value)}
            className={styles.select}
            required
          >
            <option value="" disabled>Select Gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
          <input
            type="tel"
            placeholder="Phone number"
            value={phone_number}
            onChange={(e) => setPhone(e.target.value)}
            pattern="[+]*[0-9]{1,4}[0-9]{10}"
            title="Phone number format: +(country code) (number)"
            className={styles.input}
            required
          />
          <input
            type="date"
            placeholder="Date of birth"
            value={birthdate}
            onChange={(e) => setBirthDate(e.target.value)}
            className={styles.input}
            required
          />
          <button type="submit" className={styles.button}>Signup</button>
        </form>
      </div>
    </div>
  );
}

export default Signup;
