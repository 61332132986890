import React, { useState } from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import userPool from './cognitoConfig';
import styles from './ForgotPassword.module.css';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [stage, setStage] = useState(1); // 1: Request reset, 2: Enter code and new password
  const [message, setMessage] = useState({ text: '', type: '' });

  const requestPasswordReset = () => {
    const user = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    user.forgotPassword({
      onSuccess: (data) => {
        console.log('Password reset initiated:', data);
        setMessage({ text: 'Password reset initiated. Check your email for the code.', type: 'success' });
        setStage(2); // Move to the next stage
      },
      onFailure: (err) => {
        console.error('Error requesting password reset:', err);
        setMessage({ text: err.message || 'An error occurred.', type: 'error' });
      },
    });
  };

  const confirmPasswordReset = () => {
    const user = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    user.confirmPassword(code, newPassword, {
      onSuccess: () => {
        setMessage({ text: 'Password changed successfully!', type: 'success' });
        setStage(1); // Reset to initial stage after completion
      },
      onFailure: (err) => {
        console.error('Error confirming password reset:', err);
        setMessage({ text: err.message || 'An error occurred.', type: 'error' });
      },
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <h2 className={styles.title}>Forgot Password</h2>

        {message.text && (
          <div className={`${styles.message} ${message.type === 'error' ? styles.error : styles.success}`}>
            {message.text}
          </div>
        )}

        {stage === 1 && (
          <div>
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className={styles.input}
            />
            <button onClick={requestPasswordReset} className={styles.button}>
              Request Reset
            </button>
          </div>
        )}
        {stage === 2 && (
          <div>
            <input
              type="text"
              placeholder="Enter the code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              required
              className={styles.input}
            />
            <input
              type="password"
              placeholder="Enter new password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              className={styles.input}
            />
            <button onClick={confirmPasswordReset} className={styles.button}>
              Confirm Reset
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default ForgotPassword;
