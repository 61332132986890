import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CognitoUser } from 'amazon-cognito-identity-js';
import userPool from './cognitoConfig';
import styles from './ConfirmAccount.module.css';

function ConfirmAccount() {
  const [email, setEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [message, setMessage] = useState({ text: '', type: '' }); // Holds success or error message
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const emailFromUrl = sessionStorage.getItem("email");
    if (emailFromUrl) {
      setEmail(emailFromUrl);
    }
  }, [location]);

  const onSubmit = (event) => {
    event.preventDefault();

    const user = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    user.confirmRegistration(verificationCode, true, (err, result) => {
      if (err) {
        console.error('Error during confirmation:', err);
        setMessage({ text: err.message || 'An error occurred', type: 'error' });
      } else {
        console.log('Confirmation successful:', result);
        setMessage({ text: 'Account confirmed! You can now log in.', type: 'success' });

        // Navigate to login after a short delay
        setTimeout(() => navigate('/login'), 2000);
      }
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <h2 className={styles.title}>Verify OTP</h2>
        
        {message.text && (
          <div className={`${styles.message} ${message.type === 'error' ? styles.error : styles.success}`}>
            {message.text}
          </div>
        )}

        <form onSubmit={onSubmit}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            disabled
            className={styles.input}
          />
          <input
            type="text"
            placeholder="Verification Code"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            required
            className={styles.input}
          />
          <button type="submit" className={styles.button}>
            Verify Account
          </button>
        </form>
      </div>
    </div>
  );
}

export default ConfirmAccount;
