import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkAuthorization, logout } from './authUtils';

const MainPage = () => {
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLogin, setIsLogin] = useState(false);
    const jwtToken = sessionStorage.getItem("jwt"); 

    useEffect(() => {
        let tokenUpdateInterval;

        if (!isLogin && jwtToken) {
            // Interval to update the token in cookies every 1 second
            tokenUpdateInterval = setInterval(() => {
            sessionStorage.setItem("jwt", jwtToken);
            }, 1000);
        }

        // Initially check authorization status
        if (checkAuthorization()) {
            setIsAuthenticated(true);
        }

        // Periodically check authorization status every 3 seconds if authenticated
        const intervalId = setInterval(() => {
            if (checkAuthorization()) {
                setIsAuthenticated(true);
            } else {
                setIsAuthenticated(false);
            }
        }, 3000);

        // Cleanup intervals on component unmount
        return () => {
            if (tokenUpdateInterval) clearInterval(tokenUpdateInterval);
            clearInterval(intervalId);
        };
    }, [jwtToken, isLogin]);

    const redirectToOtherApp = () => {
        const targetUrl = 'http://localhost:3001/';
        const jwtToken = sessionStorage.getItem("jwt");

        if (checkAuthorization() && jwtToken) {
            const encodedToken = encodeURIComponent(jwtToken);
            window.location.href = `${targetUrl}#id_token=${encodedToken}`;
        } else {
            logout();
        }
    };

    const handleLoginClick = () => {
        if (isAuthenticated) {
            // Immediately set to false, then clear cookies and perform logout
            setIsAuthenticated(false);
            setIsLogin(false);
            logout(); // This will clear the token cookies
        } else {
            navigate('/login');
        }
    };

    return (
        <div>
            <h1>Welcome to the Main Page</h1>
            {/* Conditionally render the button based on authentication */}
            <button onClick={handleLoginClick}>
                {isAuthenticated ? 'Logout' : 'Login'}
            </button>
            {/* Redirect to another app button */}
            <button onClick={redirectToOtherApp}>Go to Other App</button>
        </div>
    );
};

export default MainPage;
